const barInactiveColor = '#11abdb'
const barActiveColor = '#255fe5'

const CardBackground =  '#48acdb'
const ScheduledBorder = 'green'
const CountryLocationBorder = '#77fff5'

const HomeButtonIcon = '#1c76d26b'
const HomeButtonOverlayIcon = '#1c76d2'
const HomeTitleFontColor = '#116dc6'
const VersionBadgeBackground = '#aaaaaa'

const FeatureButtonSelected = '#005cd0'
const FeatureButtonSelectedFont = '#e0eeff'

const colors = {
    barInactiveColor,
    barActiveColor,
    CardBackground,
    ScheduledBorder,
    CountryLocationBorder,
    HomeButtonIcon,
    HomeButtonOverlayIcon,
    HomeTitleFontColor,
    VersionBadgeBackground,
    FeatureButtonSelected,
    FeatureButtonSelectedFont
}

export default colors