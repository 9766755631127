import time from './time'
import math from './math'
import text from './text'

const generic = {
    time,
    math,
    text
}

export default generic