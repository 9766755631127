import maps from './map'
import colors from './color'
import pins from './pin'
import status from './status'
import overlay from './overlay'
import identifiers from './identifier'
import listtype from './listtype'
import country from './country'

const BackendImageLink = process.env.REACT_APP_IMAGE_LINK

const constants = {
    maps,
    colors,
    pins,
    status,
    overlay,
    identifiers,
    listtype,
    country,
    BackendImageLink,
}

export default constants