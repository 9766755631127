import React from 'react'

function FilterBorder() {
    return (
        <div
            style={{
                height: '1px',
                display: 'block',
                background: 'linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(147,147,147,1) 40%,rgba(147,147,147,1) 60%,rgba(0,0,0,0) 100%)',
                marginBottom: '5px',
            }}
        />
    )
}

export default FilterBorder