const constants = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    RESET_MARKERS: 'RESET_MARKERS',
    ADD_MARKER: 'ADD_MARKER',
    REVOKE_MARKER: 'REVOKE_MARKER',
    EDIT_MARKER: 'EDIT_MARKER',
    REMOVE_MARKER: 'REMOVE_MARKER',
    UPDATE_MARKER_FAV: 'UPDATE_MARKER_FAV',
    RESET_EVENTTYPES: 'RESET_EVENTTYPES',
    RESET_MAPPINS: 'RESET_MAPPINS',
    UPDATE_MARKER_STATUS: 'UPDATE_MARKER_STATUS',
    RESET_SCHEDULES: 'RESET_SCHEDULES',
    ADD_SCHEDULE: 'ADD_SCHEDULE',
    EDIT_SCHEDULE: 'EDIT_SCHEDULE',
    REMOVE_SCHEDULE: 'REMOVE_SCHEDULE',
    UPDATE_SCHEDULES_STATUS: 'UPDATE_SCHEDULES_STATUS',
    UPDATE_MARKERS_STATUS: 'UPDATE_MARKERS_STATUS',
    RESET_HOME: 'RESET_HOME',
    UPDATE_RELEASE_LIST: 'UPDATE_RELEASE_LIST',
    UPDATE_RELEASE_SEEN: 'UPDATE_RELEASE_SEEN',
    UPDATE_RELEASE_LATEST: 'UPDATE_RELEASE_LATEST',
    RESET_STATIONS: 'RESET_STATIONS',
    UPDATE_STATION: 'UPDATE_STATION',
    SET_STATION_IN_MAP: 'SET_STATION_IN_MAP',
    UPDATE_FILTER: 'UPDATE_FILTER',
    UPDATE_HASHTAG: 'UPDATE_HASHTAG',
    ADD_MOVIE: 'ADD_MOVIE',
    EDIT_MOVIE: 'EDIT_MOVIE',
    RESET_MOVIES: 'RESET_MOVIES',
    RESET_ROROADLISTS: 'RESET_ROROADLISTS',
    ADD_ROROADLIST: 'ADD_ROROADLIST',
    EDIT_ROROADLIST: 'EDIT_ROROADLIST',
    MANAGE_ROROADLISTS: 'MANAGE_ROROADLISTS',
    RESET_COUNTRYCODE: 'RESET_COUNTRYCODE',
    RESET_COUNTRYPARTS: 'RESET_COUNTRYPARTS',
    RESET_FILTERCOUNTRY: 'RESET_FILTERCOUNTRY',
    ADD_COUNTRYPOINT: 'ADD_COUNTRYPOINT',
    RESET_COUNTRYPOINT: 'RESET_COUNTRYPOINT',
    ADD_COUNTRYLOCATION: 'ADD_COUNTRYLOCATION',
    RESET_COUNTRYLOCATION: 'RESET_COUNTRYLOCATION',
    RESET_CURRENTSHOW: 'RESET_CURRENTSHOW',
}

export default constants