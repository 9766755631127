import filter from './filter'
import hashtag from './hashtag'
import querys from './query'
import sorts from './sort'

const search = {
    filter,
    hashtag,
    querys,
    sorts,
}

export default search