import auth from './auth'
import users from './user'
import markers from './marker'
import markertypes from './markertype'
import pins from './pin'
import defaults from './default'
import schedules from './schedule'
import helpers from './helper'
import movies from './movie'
import releasenotes from './releasenote'
import scrappers from './scrapper'
import stations from './station'
import roroadlists from './roroadlist'
import countries from './country'

const graphql = {
    auth,
    users,
    markers,
    markertypes,
    pins,
    defaults,
    schedules,
    helpers,
    movies,
    releasenotes,
    scrappers,
    stations,
    roroadlists,
    countries,
}

export default graphql