import button from './button'
import types from './type'
import parser from './parser'
import map from './map'
import generate from './generate'
import schedules from './schedule'

const filters = {
    button,
    types,
    parser,
    map,
    generate,
    schedules,
}

export default filters