import auth from './auth'
import marker from './marker'
import schedule from './schedule'
import home from './home'
import release from './release'
import station from './station'
import filter from './filter'
import movie from './movie'
import roroadlist from './roroadlist'
import country from './country'

const reducers = {
    auth,
    marker,
    schedule,
    home,
    release,
    station,
    filter,
    movie,
    roroadlist,
    country,
}

export default reducers