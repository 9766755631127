const arrived = 'arrived'
const cancelled = 'cancelled'
const scheduled = 'scheduled'

const status = {
    arrived,
    cancelled,
    scheduled,
}

export default status