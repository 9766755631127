import markers from './marker'
import generic from './generic'
import converts from './convert'
import pins from './pin'
import sprite from './sprite'

const maphelper = {
    markers,
    generic,
    converts,
    pins,
    sprite,
}

export default maphelper