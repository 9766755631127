const movieTypeIdentifier = 'movie'
const restaurantTypeIdentifier = 'restaurant'
const markerStatusScheduled = 'scheduled'
const estimateTimeShort = 'short'

const featureMarkerUpcoming = 'upcoming'
const featureMarkerLongTimeCreated = 'longtime'
const featureMarkerFeelingLucky = 'lucky'
const featureMarkerShortTime = 'shorttime'
const featureMarkerRestaurant = 'restaurant'
const featureMarkerExpensive = 'expensive'

const identifiers = {
    movieTypeIdentifier,
    restaurantTypeIdentifier,
    markerStatusScheduled,
    estimateTimeShort,

    featureMarkerUpcoming,
    featureMarkerLongTimeCreated,
    featureMarkerFeelingLucky,
    featureMarkerShortTime,
    featureMarkerRestaurant,
    featureMarkerExpensive,
}

export default identifiers