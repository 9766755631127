import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import BottomUpTrail from '../animatein/BottomUpTrail'
import WrapperBox from '../wrapper/WrapperBox'
import FilterCircleButton from '../wrapper/FilterCircleButton'
// import FilterBox from '../filterbox/FilterBox'
import MarkerItem from './listitem/MarkerItem'

import search from '../../scripts/search'

function MarkerDisplayList({
    markers,
    setSelectedById,
    eventtypes,
    filterlist,
    // filterOption,   // below filter related
    // filterValue,
    // setFilterValue,
    // isFilterExpanded,
    // setExpandFilter,
    // confirmFilterValue,
    // finalFilterValue,
    // customFilterValue,
    // setCustomFilterValue,
    // filterOpen,
    // setShowFilter,
}) {
    const location = useLocation()
    const current_path = location.pathname

    const filteredMarkers = useMemo(() => {
        return search.filter.parse(markers, filterlist, eventtypes)
    }, [markers, filterlist, eventtypes])

    // const {
    //     filterBoxTransform,
    //     filterBoxOpacity,
    //   } = useSpring({
    //     config: config.slow,
    //     from: {
    //       filterBoxOpacity: 0,
    //       filterBoxTransform: 'scale(0, 0) translate(-100%, 3000%)',
    //     },
    //     to: {
    //       filterBoxOpacity: filterOpen ? 1 : 0,
    //       filterBoxTransform: filterOpen ? 'scale(1, 1) translate(0%, 0%)' : 'scale(0, 0) translate(-100%, 3000%)',
    //     }
    //   })

    return (
        <>
            <div 
                style={{
                    position: 'absolute',
                    height: '90%',
                    width: '95%',
                    paddingLeft: '5%',
                    paddingTop: '20px',
                    overflow: 'auto',
                }}
            >
                <BottomUpTrail>
                    {filteredMarkers.map((item, index) => (
                        <WrapperBox
                            key={index}
                            height='120px'
                            marginBottom='10px'
                        >
                            <MarkerItem
                                item={item}
                                typeIcon={eventtypes.find(s => s.value === item.type).icon_path}
                                onClickHandler={() => setSelectedById(item.id)}
                            />
                        </WrapperBox>
                    ))}
                </BottomUpTrail>
            </div>
            {/* filter related */}
            {/* button to open */}
            <div
                style={{ 
                    position: 'absolute',
                    top: '10%',
                    left: '20px',
                }}
            >
                <FilterCircleButton
                    redirectPath={'/filter' + current_path}
                />
            </div>
            {/* filter box */}
            {/* <animated.div style={{
                transform: filterBoxTransform,
                position: 'absolute',
                paddingTop: '20px',
                paddingLeft: '5%',
                width: '100%',
                transformOrigin: 'bottom left',
                visibility: filterBoxOpacity.to(o => o === 0 ? 'hidden' : 'visible'),
            }}>
                <FilterBox 
                    filterOption={filterOption}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    isExpanded={isFilterExpanded}
                    setExpand={setExpandFilter}
                    confirmFilterValue={confirmFilterValue}
                    finalFilterValue={finalFilterValue}
                    customFilterValue={customFilterValue}
                    setCustomFilterValue={setCustomFilterValue}
                />
            </animated.div> */}
        </>
    )
}

export default connect(state => ({
    eventtypes: state.marker.eventtypes,
    filterlist: state.filter.list,
  }))(MarkerDisplayList)