const list = [
    { label: 'To Go', value: 'togo' },
    { label: 'To Do', value: 'todo' },
    { label: 'Important', value: 'important' },
    { label: 'For your partner', value: 'partner' },
    { label: 'Others', value: 'others' },
]

const listtype = {
    list,
}

export default listtype